import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import { wrapper, grid, container, help, hasError } from './result.module.scss';
import { ISection } from '../../models/section.model';

import Section from '../hoc/section';
import AnimatedCheckmarkOutlined from '../atoms/animated-checkmark-outlined';
import Info from '../organisms/info';
import Markdown from '../hoc/markdown';
import { useUser } from '../../hooks/use-user';

export interface ISectionResultProps {
    section: ISection;
}

export default function Result({ section }: ISectionResultProps) {
    const { sectionId, content, css, style } = section;
    const [
        titleSuccess,
        textSuccess,
        helpTextSuccess,
        titleError,
        textError,
        helpTextError,
        subtitleSuccess,
        subtitleError,
    ] = content.texts;
    const [buttonSuccess, buttonError] = content.buttons;

    const search = typeof window !== 'undefined' ? window.location.search : '';
    const urlSearchParams = new URLSearchParams(search);
    const isError = urlSearchParams.get('error');
    const id = urlSearchParams.get('id');

    const title = isError ? titleError : titleSuccess;
    const subtitle = isError ? subtitleError : subtitleSuccess;
    const text = isError ? textError : textSuccess;
    const helpText = isError ? helpTextError : helpTextSuccess;
    const button = isError ? buttonError : buttonSuccess;

    const { isLoggedIn } = useUser();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/', { replace: true });
        }
    }, []);

    return (
        <Section
            sectionId={sectionId}
            className={wrapper}
            classes={{ container: grid }}
            css={css}
            style={style}
        >
            <Info
                className={`${container} ${isError ? hasError : ''}`}
                title={title}
                button={button}
            >
                {({ checkmarkClassName, descriptionClassName }) => (
                    <>
                        {!isError && <AnimatedCheckmarkOutlined className={checkmarkClassName} />}
                        <div className={descriptionClassName}>
                            <p>
                                {subtitle} {id && <strong>{id}</strong>}
                            </p>
                            {text && <Markdown>{text}</Markdown>}
                        </div>
                        {helpText && <Markdown className={help}>{helpText}</Markdown>}
                    </>
                )}
            </Info>
        </Section>
    );
}
